import { Component } from '@angular/core';

@Component({
  selector: 'app-site-banner',
  templateUrl: './site-banner.component.html',
  styleUrls: ['./site-banner.component.scss']
})
export class SiteBannerComponent {
  isOpenBanner = false;
}
