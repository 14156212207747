<a id="top" class="usa-skipnav" href="#main-content">Skip to main content</a>

<section class="usa-banner usa-banner--dark" aria-label="Open-source website of the FacioQuo community">
  <div class="usa-accordion">
    <header class="usa-banner__header">
      <div class="usa-banner__inner">
        <div class="grid-col-fill tablet:grid-col-auto" aria-hidden="true">
          <p class="usa-banner__header-text">
            An open-source website by FacioQuo
          </p>
          <p class="usa-banner__header-action">Here’s how you know</p>
        </div>
        <!-- we modified this to use isOpenBanner so it opens in a closed state -->
        <button type="button" class="usa-accordion__button usa-banner__button" aria-expanded="false"
          aria-controls="banner-pane" (click)="isOpenBanner=true">
          <span class="usa-banner__button-text">Here’s how you know</span>
        </button>
      </div>
    </header>
    <div class="usa-banner__content usa-accordion__content usa-dark-background" id="banner-pane" [hidden]="!isOpenBanner">
      <div class="grid-row grid-gap-lg">
        <div class="usa-banner__guidance tablet:grid-col-6">
          <img class="usa-banner__icon usa-media-block__img" src="/assets/uswds/img/icon-dot-gov.svg" role="img" alt=""
            aria-hidden="true" />
          <div class="usa-media-block__body">
            <p>
              <strong>Open-source websites can be traced back to a public GitHub repo. </strong>
              <a class="usa-link usa-link--alt usa-link--external"
                href="https://github.com/facioquo/uswds-design-patterns" target="_blank">This one</a>
              was created by FacioQuo, a GitHub open-source organization.
            </p>
          </div>
        </div>
        <div class="usa-banner__guidance tablet:grid-col-6">
          <img class="usa-banner__icon usa-media-block__img" src="/assets/uswds/img/icon-https.svg" role="img" alt=""
            aria-hidden="true" />
          <div class="usa-media-block__body">
            <p>
              <strong>Secure websites use HTTPS.</strong> A
              <strong>lock</strong> (
              <span class="icon-lock">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="64" viewBox="0 0 52 64"
                  class="usa-banner__lock-image" role="img" aria-labelledby="banner-lock-description-default"
                  focusable="false">
                  <title id="banner-lock-title-default">Lock</title>
                  <desc id="banner-lock-description-default">Locked padlock icon</desc>
                  <path fill="#000000" fill-rule="evenodd"
                    d="M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z" />
                </svg>
              </span> ) or <strong>https://</strong> means you’ve safely connected to this website. Share
              sensitive information only on official, secure websites.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
