<section class="usa-identifier usa-identifier__section usa-identifier__section--masthead"
  aria-label="Website identifier,">
  <div class="usa-identifier__container">
    <div class="usa-identifier__logos">
      <a routerLink="/" class="usa-identifier__logo">
        <img class="usa-identifier__logo-img" width="48" height="48" role="img" ngSrc="/assets/images/logo.svg" priority
          alt="Site logo" />
      </a>
    </div>
    <section class="usa-identifier__identity" aria-label="FacioQuo organization information">
      <a class="usa-identifier__identity-domain" routerLink="/">uswds.facioquo.com</a>
      <p class="usa-identifier__identity-disclaimer">
        A website by <a class="usa-link" href="https://facioquo.com" rel="noopener" target="_blank">FacioQuo</a>
      </p>
    </section>
  </div>
</section>
